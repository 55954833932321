/* global useAuth */
import { defineNuxtPlugin } from "#app";

export default defineNuxtPlugin((nuxtApp) => {
	const { data, status } = useAuth();
	nuxtApp.userRoles = {
		usersRoleName: ["HCMR-DEALER-ORDER-FULL", "HCMR-DEALER-ORDER-ONLY", "HCMR-DEALER-INQUIRY-PRICE-STOCK", "HCMR-DEALER-INQUIRY-STOCK-NOPRICE"],
		adminRoleName: "HCMR-ADMIN",
		getUserAccess() {
			return data.value?.user.access;
		},
		getUserRoles() {
			return data.value?.user.userRole;
		},
		isUser() {
			return this.usersRoleName.includes(this.getUserRoles());
		},
		isAdmin() {
			return data.value?.user.isAdmin;
		},
		isAuthenticated() {
			return status.value;
		},
	};
});
