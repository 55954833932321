import * as Sentry from "@sentry/vue";
import { useRuntimeConfig, useRouter, defineNuxtPlugin } from "nuxt/app";

export default defineNuxtPlugin((nuxtApp) => {
	const router = useRouter();
	const env = useRuntimeConfig();
	const ignoreRules = [/\[GET] "\/api\/auth\/session\?callbackUrl/, /Page not found/];

	Sentry.init({
		app: nuxtApp.vueApp,
		dsn: env.public.SENTRY_DSN,
		environment: env.public.SENTRY_ENV,
		integrations: [
			Sentry.browserTracingIntegration({ router }),
			Sentry.replayIntegration({
				maskAllText: false,
				blockAllMedia: false,
			}),
		],
		tracesSampleRate: 1.0,
		logErrors: true,
		ignoreErrors: ignoreRules,
		ignoreTransactions: ignoreRules,
	});

	nuxtApp.hook("app:error", (error) => {
		Sentry.captureException(error);
	});

	nuxtApp.provide("Sentry", Sentry);
});
