import { defineNuxtPlugin } from "#app";
import "@wangeditor/editor/dist/css/style.css";

import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import WEditor, { i18nChangeLanguage } from "@wangeditor/editor";

i18nChangeLanguage("en");
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.use(WEditor);
	nuxtApp.vueApp.component("WangEditor", Editor);
	nuxtApp.vueApp.component("WangToolbar", Toolbar);
});
