import { defineNuxtPlugin } from "#app";
import cytoscape from "cytoscape";
import klay from "cytoscape-klay";
import cytoscapePopper from "cytoscape-popper";
import { createPopper } from "@popperjs/core";

export default defineNuxtPlugin((nuxtApp) => {
	cytoscape.use(klay);
	cytoscape.use(cytoscapePopper(createPopper));

	nuxtApp.provide("cytoscape", cytoscape);
});
