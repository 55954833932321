import { defineNuxtRouteMiddleware, useCookie } from "#app";

export default defineNuxtRouteMiddleware((to) => {
	const acceptCookiesValue = useCookie("user-accept-cookies");
	if (!acceptCookiesValue.value) {
		to.meta.cookiesVisible = true;
	} else {
		to.meta.cookiesVisible = false;
	}
});
