import { default as indexx7Ikw4yAlUMeta } from "/opt/docker/pages/dealers/[id]/index.vue?macro=true";
import { default as indexvcZvchYmhfMeta } from "/opt/docker/pages/dealers/index.vue?macro=true";
import { default as indexsWiwm9unDIMeta } from "/opt/docker/pages/index.vue?macro=true";
import { default as indexecHqu2clOSMeta } from "/opt/docker/pages/login/index.vue?macro=true";
import { default as indexx09tgjmo7LMeta } from "/opt/docker/pages/orders/[id]/index.vue?macro=true";
import { default as index7iac1T33dIMeta } from "/opt/docker/pages/orders/add/index.vue?macro=true";
import { default as indexKYstsfafARMeta } from "/opt/docker/pages/orders/index.vue?macro=true";
import { default as indexXU68WBUGGRMeta } from "/opt/docker/pages/politics/index.vue?macro=true";
import { default as index6aQQ0wA5QaMeta } from "/opt/docker/pages/prices/index.vue?macro=true";
import { default as indexfB3J8HU3gDMeta } from "/opt/docker/pages/product/[id]/index.vue?macro=true";
import { default as indextMEErGiA1YMeta } from "/opt/docker/pages/product/index.vue?macro=true";
import { default as indexVwwQaRsR1JMeta } from "/opt/docker/pages/profile/change-password/index.vue?macro=true";
import { default as indexPx2R7pqVlLMeta } from "/opt/docker/pages/profile/index.vue?macro=true";
import { default as indexkVvwuDhIg6Meta } from "/opt/docker/pages/profile/password-recovery/index.vue?macro=true";
import { default as index9TKDJjqjS3Meta } from "/opt/docker/pages/search/index.vue?macro=true";
import { default as indexyACIkcQdvaMeta } from "/opt/docker/pages/users/[id]/index.vue?macro=true";
import { default as indexW8Pqht3WGrMeta } from "/opt/docker/pages/users/add/index.vue?macro=true";
import { default as indexF0PcAS5JNBMeta } from "/opt/docker/pages/users/index.vue?macro=true";
export default [
  {
    name: indexx7Ikw4yAlUMeta?.name ?? "dealers-id",
    path: indexx7Ikw4yAlUMeta?.path ?? "/dealers/:id()",
    meta: indexx7Ikw4yAlUMeta || {},
    alias: indexx7Ikw4yAlUMeta?.alias || [],
    redirect: indexx7Ikw4yAlUMeta?.redirect,
    component: () => import("/opt/docker/pages/dealers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvcZvchYmhfMeta?.name ?? "dealers",
    path: indexvcZvchYmhfMeta?.path ?? "/dealers",
    meta: indexvcZvchYmhfMeta || {},
    alias: indexvcZvchYmhfMeta?.alias || [],
    redirect: indexvcZvchYmhfMeta?.redirect,
    component: () => import("/opt/docker/pages/dealers/index.vue").then(m => m.default || m)
  },
  {
    name: indexsWiwm9unDIMeta?.name ?? "index",
    path: indexsWiwm9unDIMeta?.path ?? "/",
    meta: indexsWiwm9unDIMeta || {},
    alias: indexsWiwm9unDIMeta?.alias || [],
    redirect: indexsWiwm9unDIMeta?.redirect,
    component: () => import("/opt/docker/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexecHqu2clOSMeta?.name ?? "login",
    path: indexecHqu2clOSMeta?.path ?? "/login",
    meta: indexecHqu2clOSMeta || {},
    alias: indexecHqu2clOSMeta?.alias || [],
    redirect: indexecHqu2clOSMeta?.redirect,
    component: () => import("/opt/docker/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: indexx09tgjmo7LMeta?.name ?? "orders-id",
    path: indexx09tgjmo7LMeta?.path ?? "/orders/:id()",
    meta: indexx09tgjmo7LMeta || {},
    alias: indexx09tgjmo7LMeta?.alias || [],
    redirect: indexx09tgjmo7LMeta?.redirect,
    component: () => import("/opt/docker/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index7iac1T33dIMeta?.name ?? "orders-add",
    path: index7iac1T33dIMeta?.path ?? "/orders/add",
    meta: index7iac1T33dIMeta || {},
    alias: index7iac1T33dIMeta?.alias || [],
    redirect: index7iac1T33dIMeta?.redirect,
    component: () => import("/opt/docker/pages/orders/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexKYstsfafARMeta?.name ?? "orders",
    path: indexKYstsfafARMeta?.path ?? "/orders",
    meta: indexKYstsfafARMeta || {},
    alias: indexKYstsfafARMeta?.alias || [],
    redirect: indexKYstsfafARMeta?.redirect,
    component: () => import("/opt/docker/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexXU68WBUGGRMeta?.name ?? "politics",
    path: indexXU68WBUGGRMeta?.path ?? "/politics",
    meta: indexXU68WBUGGRMeta || {},
    alias: indexXU68WBUGGRMeta?.alias || [],
    redirect: indexXU68WBUGGRMeta?.redirect,
    component: () => import("/opt/docker/pages/politics/index.vue").then(m => m.default || m)
  },
  {
    name: index6aQQ0wA5QaMeta?.name ?? "prices",
    path: index6aQQ0wA5QaMeta?.path ?? "/prices",
    meta: index6aQQ0wA5QaMeta || {},
    alias: index6aQQ0wA5QaMeta?.alias || [],
    redirect: index6aQQ0wA5QaMeta?.redirect,
    component: () => import("/opt/docker/pages/prices/index.vue").then(m => m.default || m)
  },
  {
    name: indexfB3J8HU3gDMeta?.name ?? "product-id",
    path: indexfB3J8HU3gDMeta?.path ?? "/product/:id()",
    meta: indexfB3J8HU3gDMeta || {},
    alias: indexfB3J8HU3gDMeta?.alias || [],
    redirect: indexfB3J8HU3gDMeta?.redirect,
    component: () => import("/opt/docker/pages/product/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indextMEErGiA1YMeta?.name ?? "product",
    path: indextMEErGiA1YMeta?.path ?? "/product",
    meta: indextMEErGiA1YMeta || {},
    alias: indextMEErGiA1YMeta?.alias || [],
    redirect: indextMEErGiA1YMeta?.redirect,
    component: () => import("/opt/docker/pages/product/index.vue").then(m => m.default || m)
  },
  {
    name: indexVwwQaRsR1JMeta?.name ?? "profile-change-password",
    path: indexVwwQaRsR1JMeta?.path ?? "/profile/change-password",
    meta: indexVwwQaRsR1JMeta || {},
    alias: indexVwwQaRsR1JMeta?.alias || [],
    redirect: indexVwwQaRsR1JMeta?.redirect,
    component: () => import("/opt/docker/pages/profile/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexPx2R7pqVlLMeta?.name ?? "profile",
    path: indexPx2R7pqVlLMeta?.path ?? "/profile",
    meta: indexPx2R7pqVlLMeta || {},
    alias: indexPx2R7pqVlLMeta?.alias || [],
    redirect: indexPx2R7pqVlLMeta?.redirect,
    component: () => import("/opt/docker/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexkVvwuDhIg6Meta?.name ?? "profile-password-recovery",
    path: indexkVvwuDhIg6Meta?.path ?? "/profile/password-recovery",
    meta: indexkVvwuDhIg6Meta || {},
    alias: indexkVvwuDhIg6Meta?.alias || [],
    redirect: indexkVvwuDhIg6Meta?.redirect,
    component: () => import("/opt/docker/pages/profile/password-recovery/index.vue").then(m => m.default || m)
  },
  {
    name: index9TKDJjqjS3Meta?.name ?? "search",
    path: index9TKDJjqjS3Meta?.path ?? "/search",
    meta: index9TKDJjqjS3Meta || {},
    alias: index9TKDJjqjS3Meta?.alias || [],
    redirect: index9TKDJjqjS3Meta?.redirect,
    component: () => import("/opt/docker/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: indexyACIkcQdvaMeta?.name ?? "users-id",
    path: indexyACIkcQdvaMeta?.path ?? "/users/:id()",
    meta: indexyACIkcQdvaMeta || {},
    alias: indexyACIkcQdvaMeta?.alias || [],
    redirect: indexyACIkcQdvaMeta?.redirect,
    component: () => import("/opt/docker/pages/users/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexW8Pqht3WGrMeta?.name ?? "users-add",
    path: indexW8Pqht3WGrMeta?.path ?? "/users/add",
    meta: indexW8Pqht3WGrMeta || {},
    alias: indexW8Pqht3WGrMeta?.alias || [],
    redirect: indexW8Pqht3WGrMeta?.redirect,
    component: () => import("/opt/docker/pages/users/add/index.vue").then(m => m.default || m)
  },
  {
    name: indexF0PcAS5JNBMeta?.name ?? "users",
    path: indexF0PcAS5JNBMeta?.path ?? "/users",
    meta: indexF0PcAS5JNBMeta || {},
    alias: indexF0PcAS5JNBMeta?.alias || [],
    redirect: indexF0PcAS5JNBMeta?.redirect,
    component: () => import("/opt/docker/pages/users/index.vue").then(m => m.default || m)
  }
]