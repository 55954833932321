import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAddUserInfoForm, LazyBreadcrumbs, LazyChangeLang, LazyCookies, LazyCustomCalendar, LazyDetailItem, LazyDownloadTemplate, LazyFilter, LazyFormGroup, LazyHEditor, LazyHint, LazyLoader, LazyNotificationSettings, LazyOrderSettingsModels, LazyOrderSettings, LazyPageTitle, LazyProfileApiFormModels, LazyProfileApiForm, LazyUploadFiles, LazyUserInfoForm, LazyCalendar, LazyCheckbox, LazyDropdown, LazyInputNumber, LazyInputText, LazyMultiSelect, LazyPassword, LazyTextarea, LazyButton, LazyColumn, LazyRow, LazyColumnGroup, LazyDataTable, LazyPaginator, LazyAccordion, LazyAccordionTab, LazyCard, LazyDivider, LazyConfirmDialog, LazyDialog, LazyFileUpload, LazyBreadcrumb, LazyMenubar, LazyMessage, LazyInlineMessage, LazyChip, LazyTag, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["AddUserInfoForm", LazyAddUserInfoForm],
["Breadcrumbs", LazyBreadcrumbs],
["ChangeLang", LazyChangeLang],
["Cookies", LazyCookies],
["CustomCalendar", LazyCustomCalendar],
["DetailItem", LazyDetailItem],
["DownloadTemplate", LazyDownloadTemplate],
["Filter", LazyFilter],
["FormGroup", LazyFormGroup],
["HEditor", LazyHEditor],
["Hint", LazyHint],
["Loader", LazyLoader],
["NotificationSettings", LazyNotificationSettings],
["OrderSettingsModels", LazyOrderSettingsModels],
["OrderSettings", LazyOrderSettings],
["PageTitle", LazyPageTitle],
["ProfileApiFormModels", LazyProfileApiFormModels],
["ProfileApiForm", LazyProfileApiForm],
["UploadFiles", LazyUploadFiles],
["UserInfoForm", LazyUserInfoForm],
["Calendar", LazyCalendar],
["Checkbox", LazyCheckbox],
["Dropdown", LazyDropdown],
["InputNumber", LazyInputNumber],
["InputText", LazyInputText],
["MultiSelect", LazyMultiSelect],
["Password", LazyPassword],
["Textarea", LazyTextarea],
["Button", LazyButton],
["Column", LazyColumn],
["Row", LazyRow],
["ColumnGroup", LazyColumnGroup],
["DataTable", LazyDataTable],
["Paginator", LazyPaginator],
["Accordion", LazyAccordion],
["AccordionTab", LazyAccordionTab],
["Card", LazyCard],
["Divider", LazyDivider],
["ConfirmDialog", LazyConfirmDialog],
["Dialog", LazyDialog],
["FileUpload", LazyFileUpload],
["Breadcrumb", LazyBreadcrumb],
["Menubar", LazyMenubar],
["Message", LazyMessage],
["InlineMessage", LazyInlineMessage],
["Chip", LazyChip],
["Tag", LazyTag],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
