import revive_payload_client_4sVQNw7RlN from "/opt/docker/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/docker/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/docker/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/docker/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_YdLad5Mpq3 from "/opt/docker/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/docker/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/docker/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_W86eSGFqyZ from "/opt/docker/node_modules/nuxt-viewport/dist/runtime/plugin.client.mjs";
import primevue_plugin_egKpok8Auk from "/opt/docker/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/opt/docker/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/opt/docker/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/opt/docker/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_AOuQ1DYzjk from "/opt/docker/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/opt/docker/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import cytoscape_client_NaM8a82Mlc from "/opt/docker/plugins/cytoscape.client.js";
import editor_client_PuwCzrQ8DT from "/opt/docker/plugins/editor.client.js";
import debounce_4dZlUi0mTt from "/opt/docker/plugins/debounce.js";
import excel_56ugge7MPo from "/opt/docker/plugins/excel.js";
import floating_vue_Grexwy95Ly from "/opt/docker/plugins/floating-vue.js";
import sentry_client_GoGQuZo4Et from "/opt/docker/plugins/sentry.client.js";
import user_roles_gJLc3b4AO1 from "/opt/docker/plugins/user-roles.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_W86eSGFqyZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_AOuQ1DYzjk,
  chunk_reload_client_UciE0i6zes,
  cytoscape_client_NaM8a82Mlc,
  editor_client_PuwCzrQ8DT,
  debounce_4dZlUi0mTt,
  excel_56ugge7MPo,
  floating_vue_Grexwy95Ly,
  sentry_client_GoGQuZo4Et,
  user_roles_gJLc3b4AO1
]